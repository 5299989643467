import React, { useState } from 'react';
import { StaticImageData } from 'next/image';

import { useTheme } from 'styled-components';

import { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import {
    Container,
    ImageContainer,
    StyledImage,
    Wave,
    Inner,
    Heading,
    Description,
    StyledArrowButton
} from './Listing.styled';

export interface Props {
    image: {
        alt?: string;
        src: StaticImageData | string;
    };
    heading: string;
    description: string;
    button: ArrowLinkButtonProps;
    backgroundColour?: string;
    colour?: string;
}

const Listing: React.FC<Props> = props => {
    const { colors } = useTheme();

    const {
        image,
        heading,
        description,
        button,
        backgroundColour = `${colors.accents[10].hex}`,
        colour = `${colors.accents[4].hex}`
    } = props;

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Container $backgroundColour={backgroundColour}>
            <ImageContainer $backgroundColour={backgroundColour}>
                <StyledImage
                    src={image.src}
                    alt={image.alt || ''}
                    width={312}
                    height={184}
                    $isHovering={isHovering}
                />
                <Wave $backgroundColour={backgroundColour} $isHovering={isHovering} fill={colour} />
            </ImageContainer>
            <Inner>
                <Heading $colour={colour}>{heading}</Heading>
                <Description>{description}</Description>
                <StyledArrowButton
                    {...button}
                    onMouseOver={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                />
            </Inner>
        </Container>
    );
};

export default Listing;
