import styled, { css } from 'styled-components';
import NextImage from 'next/image';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/cards/listing/wave-card-listing.svg';
import ArrowLinkButton from 'Elements/Button/Arrow';

export const Container = styled.div<{ $backgroundColour: string }>`
    ${({ $backgroundColour }) => css`
        position: relative;
        background: ${$backgroundColour};
        border-radius: 0.5rem;
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    `}
`;

export const ImageContainer = styled.div<{ $backgroundColour: string }>`
    ${({ theme, $backgroundColour }) => css`
        @media ${theme.breakpoints.large.media} {
            position: relative;
            overflow: hidden;

            :before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: transparent
                    linear-gradient(
                        180deg,
                        transparent 0%,
                        ${$backgroundColour}00 44%,
                        ${$backgroundColour}a6 65%,
                        ${$backgroundColour}ce 85%,
                        ${$backgroundColour} 100%
                    );
            }
        }
    `}
`;

export const StyledImage = styled(NextImage)<{ $isHovering: boolean }>`
    ${({ theme, $isHovering }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
            max-width: 100%;
            height: auto;
            transition-duration: 0.3s;
            transform: ${$isHovering ? 'scale(1.1)' : 'scale(1)'};
        }
    `}
`;

export const Wave = styled(WaveSVG)<{ $backgroundColour: string; $isHovering: boolean }>`
    ${({ theme, $backgroundColour, $isHovering }) => css`
        position: absolute;
        width: 55rem;
        transform: scaleX(-1);
        top: -4rem;
        left: 5%;
        mask-image: linear-gradient(
            to left,
            ${$backgroundColour}00 0%,
            ${$backgroundColour}00 10%,
            ${$backgroundColour}a6 50%,
            ${$backgroundColour}
        );

        @media ${theme.breakpoints.large.media} {
            top: unset;
            transform: none;
            width: 66rem;
            bottom: -4.5rem;
            left: -22rem;
            mask-image: none;
            transition-duration: 0.3s;
            transform: ${$isHovering ? 'translateX(-5%)' : 'translateX(0%)'};
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        padding: 1.375rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        @media ${theme.breakpoints.large.media} {
            padding: 0 1rem 1rem;
            flex-direction: column;
            flex-grow: 1;
        }
    `}
`;

export const Heading = styled.h3<{ $colour: string }>`
    ${({ theme, $colour }) => css`
        color: ${$colour};
        font-size: 1.375rem;
        line-height: 1.1;
        flex: 0 0 6.25rem;
        margin: 0;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.75rem;
            flex: 0 0 auto;
            text-align: center;
            margin: 0.5rem 0 0;
        }
    `}
`;

export const Description = styled.p`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
            color: ${theme.colors.primaryContrast.hex};
            font-size: 1.125rem;
            text-align: center;
            margin-top: 0.5rem;
            flex: 1 0 auto;
        }
    `}
`;

export const StyledArrowButton = styled(ArrowLinkButton)`
    ${({ theme }) => css`
        --button-padding-y: 0.5rem;
        font-size: 1.125rem;

        @media ${theme.breakpoints.large.media} {
            --button-padding-y: 0.6rem;
            width: 100%;
            font-size: 1.25rem;
        }
    `}
`;
